html,
body {
  min-height: 100%;
  padding: 0;
  margin: 0;
  font-family:
    -apple-system,
    BlinkMacSystemFont,
    Segoe UI,
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    Fira Sans,
    Droid Sans,
    Helvetica Neue,
    sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.loader-overlay {
  position: fixed;

  top: 0;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 100vw;
  height: 100vw;

  background: rgba(0, 0, 0, 0.1);

  /*&_light {*/
  /*   background: rgba(255, 255, 255, 0.7);*/
  /* }*/
}

.loader-overlay .circle-loader.circle-loader--centred {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.circle-loader.MuiCircularProgress-colorPrimary {
  /*color: $color--main-ui;*/
}
